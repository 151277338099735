import { Component } from '@angular/core';
import {EventBusRootService} from "@com-bam/lib-micro-communication";

@Component({
  selector: 'app-root-pwd-recovery',
  templateUrl: './pwd-recovery.component.html'
})
export class PwdRecoveryComponent {
  constructor(private eventBus: EventBusRootService) {
  }
  changeLanguage(): void {
    this.eventBus.emit({name: 'change_language', data: sessionStorage.getItem('language')});
  }
}
