import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';

import {ModalContentService} from '../modal-content/services/modal-content.service';

@Component({
  selector: 'root-modal-content',
  templateUrl: 'modal-content.component.html',
  styleUrls: ['modal-content.component.scss'],
  encapsulation: ViewEncapsulation.None
})

/**
 @Description Component that is responsible for render ModalContentComponent
 @Params id: string (Modal has to have an id, which is what you will use to open or close it),
  columnStyles: string (bootstrap convention, to customize its width), 
  closeModalIcon: boolean (Pass it as false in case you dont want to see the icon to close it), 
  closeModalIconMobile: boolean (Pass it as false in case you dont want to see the icon to close it in mobile), 
  listenEsc: boolean (Pass it as false in case you dont want the modal to close when pressing escape)
  isLargeModal: boolean (Pass it as true if you want the modal to be wider than 600px)
  @Events modalClosed (tells you when the modal was closed), 
 modalSubmit (tells you when you press enter on the modal).
 @author {jorge.chaparro} - Dev
 */

export class ModalContentComponent implements OnInit, OnDestroy {

  constructor(private modalService: ModalContentService, private el: ElementRef) {
    this.element = el.nativeElement;
  }
  @Input() id = '';
  @Input() columnStyles = 'col-8';
  @Input() closeModalIcon = true;
  @Input() listenEsc = true;
  @Input() closeModalIconMobile = false;
  @Input() isLargeModal = false;
  @Output() modalClosed = new EventEmitter();
  @Output() modalSubmit = new EventEmitter();

  private element: any;

  @HostListener('document:keydown.escape', ['$event']) onKeyEscHandler(event: KeyboardEvent): void {
    if (this.element.style.display === 'block' && this.listenEsc) {
      this.close();
    }
  }

  @HostListener('document:keydown.enter', ['$event']) onKeyEnterHandler(event: KeyboardEvent): void {
    if (this.element.style.display === 'block') {
      this.modalSubmit.emit();
    }
  }

  ngOnInit(): void {
    // ensure id attribute exists
    if (!this.id) {
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
  }

  // remove self from modal service when component is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  // open modal
  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('lib-content-modal-open');
    document.body.classList.add('front-lib-new-notification');
  }

  // close modal
  close(justHide?:boolean): void {
    this.element.style.display = 'none';
    document.body.classList.remove('lib-content-modal-open');
    document.body.classList.remove('front-lib-new-notification');
    if(!justHide){
      this.modalClosed.emit();
    }
  }

  closeModal(): void {
    this.modalClosed.emit();
    this.element.style.display = 'none';
    document.body.classList.remove('lib-content-modal-open');
    document.body.classList.remove('front-lib-new-notification');
  }

  setElement(element: any): void {
    this.element = element;
  }
}
