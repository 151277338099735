import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root-withdraw-deposit-accounts',
  templateUrl: './withdraw-deposit-accounts.component.html'
})
export class WithdrawDepositAccountsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
