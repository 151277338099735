<div class="lib-content-modal">
  <div
    class="lib-content-modal-body {{ columnStyles }} {{
      isLargeModal ? '' : 'not-large-modal'
    }}"
  >
    <div
      *ngIf="closeModalIcon"
      class="row justify-content-end m-1 align {{
        closeModalIconMobile ? '' : 'd-none d-md-flex'
      }}"
    >
      <i class="icon-error px-0" (click)="closeModal()" role="button"></i>
    </div>
    <ng-content></ng-content>
  </div>
</div>
<div class="lib-content-modal-background"></div>
