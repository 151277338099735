import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthorizationGuard} from './core/guard/authorization.guard';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
import {ModalContentModule} from './components/modal-content/modal-content.module';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ErrorInterceptor} from '@com-bam/lib-micro-communication';
import {RolesComponent} from './containers/roles/roles.component';
import {CommonModule} from '@angular/common';
import {ApprovalFlowComponent} from './containers/approval-flow/approval-flow.component';
import {HeaderMenuModule} from './components/header-menu/header-menu.module';
import {ProductsGroupComponent} from './containers/products-group/products-group.component';
import {CreateUsersComponent} from './containers/create-users/create-users.component';
import {PendingApprovalsComponent} from './containers/pending-approvals/pending-approvals.component';
import {ConsolidatedProductsComponent} from './containers/consolidated-products/consolidated-products.component';
import {TransferOwnAccountsComponent} from "./containers/transfer-own-accounts/transfer-own-accounts.component";
import {ChequesComponent} from './containers/cheques/cheques.component';
import {FavoritesComponent} from './containers/favorites/favorites.component';
import {AchTransferComponent} from './containers/ach-transfer/ach-transfer.component';
import {TransferThirdsBamComponent} from "./containers/transfer-thirds-bam/transfer-thirds-bam.component";
import {BridgeComponent} from './containers/bridge/bridge.component';
import {DeclaraguateComponent} from './containers/declaraguate/declaraguate.component';
import {TdcPaymentComponent} from './containers/tdc-payment/tdc-payment.component';
import {PayrollComponent} from './containers/payroll/payroll.component';
import {TransferInternationalComponent} from './containers/transfer-international/transfer-international.component';
import {TransferOtherBanksComponent} from "./containers/transfer-other-banks/transfer-other-banks.component";
import {
    WithdrawDepositAccountsComponent
} from './containers/withdraw-deposit-accounts/withdraw-deposit-accounts.component';
import {PaymentServicesComponent} from './containers/payment-services/payment-services.component';
import {CashAdvanceComponent} from './containers/cash-advance/cash-advance.component';
import {TDDComponent} from './containers/tdd/tdd.component';
import {LimitsTdcComponent} from './containers/limits-tdc/limits-tdc.component';
import {EasyCashComponent} from './containers/easy-cash/easy-cash.component';
import {ConfigureCardsComponent} from './containers/configure-cards/configure-cards.component';
import {AccountStatementsComponent} from './containers/account-statements/account-statements.component';
import {FrontWebLibModule} from "@com-bam/front-web-lib";
import {ReactiveFormsModule} from "@angular/forms";
import {ConsultScheduledComponent} from './containers/consult-scheduled/consult-scheduled.component';
import {LanguageModalComponent} from "./components/header-menu/language-modal/language-modal.component";
import {LoginComponentConfig} from "./containers/login/login.component.config";
import {FooterModule} from "./components/footer/footer.module";
import {UsersRegistrationComponent} from "./containers/users-registration/users-registration.component";
import {UserRecoveryComponent} from "./containers/user-recovery/user-recovery.component";
import {PwdRecoveryComponent} from "./containers/pwd-recovery/pwd-recovery.component";
import {TravelNoticeComponent} from "./containers/travel-notice/travel-notice.component";
import {AndroidModule} from "./containers/android/android.module";
import { VinculacionLoadComponent } from './containers/vinculacion-load/vinculacion-load.component';


export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        RolesComponent,
        ApprovalFlowComponent,
        ProductsGroupComponent,
        CreateUsersComponent,
        PendingApprovalsComponent,
        ConsolidatedProductsComponent,
        TransferOwnAccountsComponent,
        TransferThirdsBamComponent,
        TransferOtherBanksComponent,
        ChequesComponent,
        FavoritesComponent,
        AchTransferComponent,
        BridgeComponent,
        DeclaraguateComponent,
        TdcPaymentComponent,
        PayrollComponent,
        TransferInternationalComponent,
        WithdrawDepositAccountsComponent,
        PaymentServicesComponent,
        CashAdvanceComponent,
        TDDComponent,
        LimitsTdcComponent,
        EasyCashComponent,
        ConfigureCardsComponent,
        AccountStatementsComponent,
        ConsultScheduledComponent,
        LanguageModalComponent,
        LoginComponentConfig,
        UsersRegistrationComponent,
        UserRecoveryComponent,
        PwdRecoveryComponent,
        VinculacionLoadComponent,
        TravelNoticeComponent

    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        CommonModule,
        FrontWebLibModule,
        HttpClientModule,
        ModalContentModule,
        NgIdleKeepaliveModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            defaultLanguage: sessionStorage.getItem('language') || 'es'
        }),
        HeaderMenuModule,
        ReactiveFormsModule,
        FooterModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        AuthorizationGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        }
    ],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
