import { NgModule } from '@angular/core';
import { ModalContentComponent } from './modal-content.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    ModalContentComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [ ModalContentComponent ],
  providers: [],
})
export class ModalContentModule { }
