import {
    hostLoginConfig,
    hostHomeConfig,
    hostUserRecoveryConfig,
    hostPwdRecoveryConfig,
    hostUsersRegistrationConfig,
    hostChangePasswordConfig,
    hostRolesConfig,
    hostCreateUsersConfig,
    hostProductsGroupConfig,
    hostApprovalFlowConfig,
    hostPendingApprovalsConfig,
    hostConsolidatedProductsConfig,
    hostTransferOwnAccountsConfig,
    hostLoansPaymentConfig,
    hostChequesConfig,
    hostFavoritesConfig,
    hostAchTransferConfig,
    hostBancaSatConfig,
    hostTransferThirdsBamConfig,
    hostDeclaraguatePaymentConfig,
    hostTdcPaymentConfig,
    hostPayrollConfig,
    hostTransferOtherBanksConfig,
    hostTransferInternationalConfig,
    hostPaymentServicesConfig,
    hostWithdrawDepositAccountsConfig,
    hostCashAdvanceConfig,
    hostTDDConfig,
    hostEasyCashConfig,
    hostTDCconfig,
    hostConfigureCardsconfig,
    hostCustomReportConfig,
    hostConsultTransactionConfig,
    hostAccountStatementsConfig,
    hostConsultMassiveConfig,
    hostConsultScheduledConfig,
    hostConsultExchangeConfig,
    hostLoadInformationVDConfig,
    hostTravelNoticeConfig
} from './host.config';

// @ts-ignore
const srcLogin = (hostLoginConfig === 'LOGIN_CONFIG' ? 'http://localhost:4201' : hostLoginConfig) + '/main.js';
// @ts-ignore
const srcHome = (hostHomeConfig === 'HOME_CONFIG' ? 'http://localhost:4202' : hostHomeConfig) + '/main.js';
// @ts-ignore
const srcUserRecovery = (hostUserRecoveryConfig === 'USER_RECOVERY_CONFIG' ? 'http://localhost:4203' : hostUserRecoveryConfig) + '/main.js';
// @ts-ignore
const srcPwdRecovery = (hostPwdRecoveryConfig === 'PWD_RECOVERY_CONFIG' ? 'http://localhost:4204' : hostPwdRecoveryConfig) + '/main.js';
// @ts-ignore
const srcUsersRegistration = (hostUsersRegistrationConfig === 'USERS_REGISTRATION_CONFIG' ? 'http://localhost:4205' : hostUsersRegistrationConfig) + '/main.js';
// @ts-ignore
const srcChangePassword = (hostChangePasswordConfig === 'CHANGE_PASSWORD_CONFIG' ? 'http://localhost:4206' : hostChangePasswordConfig) + '/main.js';
// @ts-ignore
const srcCreateUsers = (hostCreateUsersConfig === 'CHANGE_CREATE_USERS_CONFIG' ? 'http://localhost:4207' : hostCreateUsersConfig) + '/main.js';
// @ts-ignore
const srcRoles = (hostRolesConfig === 'ROLES_CONFIG' ? 'http://localhost:4207' : hostRolesConfig) + '/main.js';
// @ts-ignore
const srcProductsGroups = (hostProductsGroupConfig === 'PRODUCTS_GROUPS_CONFIG' ? 'http://localhost:4209' : hostProductsGroupConfig) + '/main.js';
// @ts-ignore
const srcApprovalFlow = (hostApprovalFlowConfig === 'APPROVAL_FLOW_CONFIG' ? 'http://localhost:4217' : hostApprovalFlowConfig) + '/main.js';
// @ts-ignore
const srcPendingApprovals = (hostPendingApprovalsConfig === 'PENDING_APPROVALS_CONFIG' ? 'http://localhost:4210' : hostPendingApprovalsConfig) + '/main.js';
// @ts-ignore
const srcConsolidatedProducts = (hostConsolidatedProductsConfig === 'CONSOLIDATED_PRODUCTS_CONFIG' ? 'http://localhost:4211' : hostConsolidatedProductsConfig) + '/main.js';
// @ts-ignore
const srcLoansPayment = (hostLoansPaymentConfig === 'LOANS_PAYMENT_CONFIG' ? 'http://localhost:4213' : hostLoansPaymentConfig) + '/main.js';
// @ts-ignore
const srcTransferOWnAccounts = (hostTransferOwnAccountsConfig === 'TRANSFER_OWN_ACCOUNTS_CONFIG' ? 'http://localhost:4212' : hostTransferOwnAccountsConfig) + '/main.js';
// @ts-ignore
const srcCheques = (hostChequesConfig === 'CHEQUES_CONFIG' ? 'http://localhost:4214' : hostChequesConfig) + '/main.js';
// @ts-ignore
const srcFavorites = (hostFavoritesConfig === 'FAVORITES_CONFIG' ? 'http://localhost:4215' : hostFavoritesConfig) + '/main.js';
// @ts-ignore
const srcBancaSAT = (hostBancaSatConfig === 'BANCA_SAT_CONFIG' ? 'http://localhost:4216' : hostBancaSatConfig) + '/main.js';
// @ts-ignore
const srcTransferThirdsBam = (hostTransferThirdsBamConfig === 'TRANSFER_THIRDS_BAM_CONFIG' ? 'http://localhost:4218' : hostTransferThirdsBamConfig) + '/main.js';
// @ts-ignore
const srcDeclaraguatePayment = (hostDeclaraguatePaymentConfig === 'DECLARAGUATE_PAYMENT_CONFIG' ? 'http://localhost:4219' : hostDeclaraguatePaymentConfig) + '/main.js';
// @ts-ignore
const srcPayroll = (hostPayrollConfig === 'PAYROLL_CONFIG' ? 'http://localhost:4216' : hostPayrollConfig) + '/main.js';
// @ts-ignore
const srcAchTransfer = (hostAchTransferConfig === 'ACH_TRANSFER_CONFIG' ? 'http://localhost:4220' : hostAchTransferConfig) + '/main.js';
// @ts-ignore
const srcTdcPayment = (hostTdcPaymentConfig === 'TDC_PAYMENT_CONFIG' ? 'http://localhost:4221' : hostTdcPaymentConfig) + '/main.js';
// @ts-ignore
const srcTransferInternational = (hostTransferInternationalConfig === 'TRANSFER_INTERNATIONAL' ? 'http://localhost:4222' : hostTransferInternationalConfig) + '/main.js';
// @ts-ignore
const srcPaymentServices = (hostPaymentServicesConfig === 'PAYMENT_SERVICES' ? 'http://localhost:4223' : hostPaymentServicesConfig) + '/main.js';
// @ts-ignore
const srcTransferOtherBanks = (hostTransferOtherBanksConfig === 'TRANSFER_OTHER_BANKS_CONFIG' ? 'http://localhost:4222' : hostTransferOtherBanksConfig) + '/main.js';
// @ts-ignore
const srcWithdrawDepositAccounts = (hostWithdrawDepositAccountsConfig === 'WITHDRAW_DEPOSIT_ACCOUNTS_CONFIG' ? 'http://localhost:4223' : hostWithdrawDepositAccountsConfig) + '/main.js';
// @ts-ignore
const srcCashAdvance = (hostCashAdvanceConfig === 'CASH_ADVANCE_CONFIG' ? 'http://localhost:4223' : hostCashAdvanceConfig) + '/main.js';
// @ts-ignore
const srcTDD = (hostTDDConfig === 'TDD_CONFIG' ? 'http://localhost:4225' : hostTDDConfig) + '/main.js';
// @ts-ignore
const srcEasyCash = (hostEasyCashConfig === 'EASY_CASH_CONFIG' ? 'http://localhost:4224' : hostEasyCashConfig) + '/main.js';
// @ts-ignore
const srcTDC = (hostTDCconfig === 'TDC_CONFIG' ? 'http://localhost:4227' : hostTDCconfig) + '/main.js';
// @ts-ignore
const srcConfigureCards = (hostConfigureCardsconfig === 'CONFIGURE_CARDS_CONFIG' ? 'http://localhost:4228' : hostConfigureCardsconfig) + '/main.js';
// @ts-ignore
const srcCustomReport = (hostCustomReportConfig === 'CUSTOM_REPORT_CONFIG' ? 'http://localhost:4228' : hostCustomReportConfig) + '/main.js';
// @ts-ignore
const srcConsultTransaction = (hostConsultTransactionConfig === 'CONSULT_TRANSACTION_CONFIG' ? 'http://localhost:4229' : hostConsultTransactionConfig) + '/main.js';
// @ts-ignore
const srcAccountStatements = (hostAccountStatementsConfig === 'ACCOUNT_STATEMENTS_CONFIG' ? 'http://localhost:4230' : hostAccountStatementsConfig) + '/main.js';
// @ts-ignore
const srcConsultMassive = (hostConsultMassiveConfig === 'CONSULT_MASSIVE_CONFIG' ? 'http://localhost:4231' : hostConsultMassiveConfig) + '/main.js';
// @ts-ignore
const srcConsultScheduled = (hostConsultScheduledConfig === 'CONSULT_SCHEDULED_CONFIG' ? 'http://localhost:4232' : hostConsultScheduledConfig) + '/main.js';
// @ts-ignore
const srcConsultExchange = (hostConsultExchangeConfig === 'CONSULT_EXCHANGE_CONFIG' ? 'http://localhost:4233' : hostConsultExchangeConfig) + '/main.js';
// @ts-ignore
const srcTravelNotice = (hostTravelNoticeConfig === 'TRAVEL_NOTICE_CONFIG' ? 'http://localhost:4234' : hostTravelNoticeConfig) + '/main.js';

// @ts-ignore
const srcVinculacionLoad = ( hostLoadInformationVDConfig === 'LOAD_INFORMATION_VD_CONFIG' ? 'http://localhost:4235' : hostLoadInformationVDConfig) + '/main.js';


const MICRO_FRONTEND = {
  MICRO_LOGIN: {
    NAME: 'login',
    SRC: srcLogin
  },
  MICRO_USER_RECOVERY: {
    NAME: 'userRecovery',
    SRC: srcUserRecovery
  },
  MICRO_USERS_REGISTRATION: {
    NAME: 'usersRegistration',
    SRC: srcUsersRegistration
  },
  MICRO_HOME: {
    NAME: 'home',
    SRC: srcHome
  },
  MICRO_PWD_RECOVERY: {
    NAME: 'pwdRecovery',
    SRC: srcPwdRecovery
  },
  CHANGE_PASSWORD: {
    NAME: 'changePassword',
    SRC: srcChangePassword
  },
  CHANGE_CREATE_USERS: {
    NAME: 'createUsers',
    SRC: srcCreateUsers
  },
  MICRO_ROLES: {
    NAME: 'roles',
    SRC: srcRoles
  },
  PRODUCTS_GROUP: {
    NAME: 'productsGroup',
    SRC: srcProductsGroups
  },
  APPROVAL_FLOW: {
    NAME: 'approvalFlow',
    SRC: srcApprovalFlow
  },
  PENDING_APPROVALS: {
    NAME: 'pendingApprovals',
    SRC: srcPendingApprovals
  },
  CONSOLIDATED_PRODUCTS: {
    NAME: 'consolidatedProducts',
    SRC: srcConsolidatedProducts
  },
  LOANS_PAYMENT: {
    NAME: 'loansPayment',
    SRC: srcLoansPayment
  },
  TRANSFER_OWN_ACCOUNTS: {
    NAME: 'transferOwnAccounts',
    SRC: srcTransferOWnAccounts
  },
  ACH_TRANSFER: {
    NAME: 'achTransfer',
    SRC: srcAchTransfer
  },
  MICRO_CHEQUES: {
    NAME: 'cheques',
    SRC: srcCheques
  },
  MICRO_FAVORITES: {
    NAME: 'favorites',
    SRC: srcFavorites
  },
  MICRO_BANCASAT: {
    NAME: 'bancaSAT',
    SRC: srcBancaSAT
  },
  TRANSFER_THIRDS_BAM: {
    NAME: 'transferThirdsBam',
    SRC: srcTransferThirdsBam
  },
  DECLARAGUATE_PAYMENT: {
    NAME: 'declaraguatePayment',
    SRC: srcDeclaraguatePayment
  },
  TDC_PAYMENT: {
    NAME: 'tdcPayment',
    SRC: srcTdcPayment
  },
  MICRO_PAYROLL: {
    NAME: 'payroll',
    SRC: srcPayroll
  },
  MICRO_TRANSFER_INTERNATIONAL: {
    NAME: 'transferInternational',
    SRC: srcTransferInternational
  },
  MICRO_PAYMENT_SERVICES: {
    NAME: 'paymentServices',
    SRC: srcPaymentServices
  },
  MICRO_TRANSFER_OTHER_BANKS: {
      NAME: 'transferOtherBanks',
      SRC: srcTransferOtherBanks
  },
  MICRO_WITHDRAW_DEPOSIT_ACCOUNTS: {
      NAME: 'withdrawDepositAccounts',
      SRC: srcWithdrawDepositAccounts
  },
  MICRO_CASH_ADVANCE: {
    NAME: 'cashAdvance',
    SRC: srcCashAdvance
  },
  MICRO_TDD: {
    NAME: 'tdd',
    SRC: srcTDD
  },
  MICRO_EASY_CASH: {
    NAME: 'easyCash',
    SRC: srcEasyCash
  },
  MICRO_LIMITS_TDC: {
    NAME: 'tdc',
    SRC: srcTDC
  },
  MICRO_CONFIGURE_CARDS: {
    NAME: 'configureCards',
    SRC: srcConfigureCards
  },
  MICRO_CUSTOM_REPORT: {
    NAME: 'customReport',
    SRC: srcCustomReport
  },
  MICRO_CONSULT_TRANSACTION: {
    NAME: 'consultTransaction',
    SRC: srcConsultTransaction
  },
  MICRO_ACCOUNT_STATEMENTS: {
    NAME: 'accountStatements',
    SRC: srcAccountStatements
  },
  MICRO_CONSULT_MASSIVE: {
    NAME: 'consultMassive',
    SRC: srcConsultMassive
  },
  MICRO_CONSULT_SCHEDULED: {
    NAME: 'consultScheduled',
    SRC: srcConsultScheduled
  },
  MICRO_TRAVEL_NOTICE: {
    NAME: 'travelNotice',
    SRC: srcTravelNotice
  },
  MICRO_CONSULT_EXCHANGE: {
    NAME: 'consultExchange',
    SRC: srcConsultExchange
  },
  LOAD_INFORMATION_VD_CONFIG: {
    NAME: 'vinculacionLoad',
    SRC: srcVinculacionLoad
  },

};

export {MICRO_FRONTEND};
