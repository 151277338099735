// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    environment: 'DEV',
    assets: 'https://assets.devbam.com/contenido_compartido/',
    versionApp: '1.0',
    API_SERVICES: {
        API_COMMONS: 'https://commons.devbam.com/',
        API_PEOPLE: 'https://people.devbam.com/',
        API_ENTERPRISES: 'https://sve.devbam.com/'
    },
    securityImage: 'https://assets.devbam.com/imagenes_seguridad/',
    production: false
};
export const clientID = 'sve';
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
