import { Component } from '@angular/core';
import { EventBusRootService } from '@com-bam/lib-micro-communication';
import { environment } from '../../../environments/environment';
import {LogoutService} from '../../services/logout/logout.service';
import {TranslateService} from "@ngx-translate/core";
@Component({
  selector: 'app-root-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  footerLinks = [];
  year = (new Date()).getFullYear();
  logoFooter = environment.assets + 'bancolombia-group-logo.svg';
  logoDigicert = environment.assets + 'digicert-logo.svg';

  constructor(public eventBus: EventBusRootService,
    private readonly logoutService: LogoutService,
              public translate: TranslateService) {
    this.setTextLanguage();
    this.translate.onLangChange?.subscribe(() => {
      this.footerLinks = [];
      this.setTextLanguage();
    });
  }

  setTextLanguage(): void{
    this.translate.get(['footer']).subscribe((i18n: any) => {
      this.footerLinks.push({ label: i18n?.footer?.item1, route: ''});
      this.footerLinks.push({ label: i18n?.footer?.item2, route: 'https://www.bam.com.gt/terminos-y-condiciones/'});
      this.footerLinks.push({ label: i18n?.footer?.item3, route: ''});
      this.footerLinks.push({ label: i18n?.footer?.item4, route: ''});
    });
  }

}
