import {ChangeDetectorRef, Component, NgZone, OnInit} from '@angular/core';
import {ConfigService, EventBusRootService, GoogleAnalyticsService} from '@com-bam/lib-micro-communication';
import {NavigationStart, Router} from '@angular/router';
import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';
import {ModalContentService} from './components/modal-content/services/modal-content.service';
import {LogoutService} from './services/logout/logout.service';
import {microsRegistering} from './microregister.config';
import { Location } from '@angular/common';
import {fromEvent, Observable, Subscription} from 'rxjs';
import {TranslateService} from "@ngx-translate/core";
import { LoadSpinnerService } from '@com-bam/front-web-lib';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    timedOut = false;
    isRegisterApp = false;
    inactivityTime = 420;
    offlineEvent: Observable<Event>;
    onlineEvent: Observable<Event>;
    subscriptions: Subscription[] = [];

    constructor(private eventBus: EventBusRootService,
                private readonly router: Router,
                private readonly idle: Idle,
                private readonly ngZone: NgZone,
                public loader: LoadSpinnerService,
                private readonly modalService: ModalContentService,
                public logOutService: LogoutService,
                private readonly configService: ConfigService,
                private readonly googleAnalyticsService: GoogleAnalyticsService,
                private readonly location: Location,
                public translate: TranslateService,
                private readonly ref: ChangeDetectorRef
    ) {
        this.translate.reloadLang(sessionStorage.getItem('language') || 'es');
        this.translate.use(sessionStorage.getItem('language') || 'es');
        this.isRegisterApp = true;
        idle.setIdle((this.inactivityTime * 0.7) - 5);  
        idle.setTimeout(5);
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        idle.onIdleEnd.subscribe(() => {
            this.reset();
        });

        idle.onTimeout.subscribe(() => {
            if (this.eventBus.getUserInfo()) {
                this.timedOut = true;
                this.modalService.open('inactivity-modal');
                this.logOutService.revokeToken().subscribe();
            }
        });

        router.events.subscribe((event: NavigationStart) => {
            if (event.navigationTrigger === 'popstate') {
                let sameRootUrl = event.url.split('/').length >= 1 && router.url.includes(event.url.split('/')[1]);
                if(!sameRootUrl){
                    this.router.navigate([this.router.url]);
                    this.location.go(this.router.url)
                }
            }
        });
    }

    ngOnInit(): void {
        if (this.isRegisterApp) {
            microsRegistering(this.eventBus);
            this.listenEventBus();
        }
        this.handleAppConnectivityChanges();
    }

    clickCloseLanguage(): void{
        this.translate.use(sessionStorage.getItem('language'));
        this.logOutService.showChangeLanguage = false;
    }

    private handleAppConnectivityChanges(): void {
        this.onlineEvent = fromEvent(window, 'online');
        this.offlineEvent = fromEvent(window, 'offline');

        this.subscriptions.push(this.offlineEvent.subscribe(e => {
            this.ngZone.run(() => {
                this.router.navigate(['/']);
            });
        }));
    }

    listenEventBus(): void {
        this.eventBus.on('mountedApp', (micro: any) => {
            this.reset();
            this.checkRedirection(micro);
        });
        this.eventBus.on('stopActivity', (micro: any) => {
            this.idle.stop();
        });
        this.eventBus.on('userInfo', (data: any) => {
            this.eventBus.setUserInfo(data);
            this.listenForRefreshToken(data.secret);
            this.inactivityTime = data.secret.expires_in > 300 ? data.secret.expires_in : 600;
        });
        this.eventBus.on('onHttpError', (error: any) => {
            if ((error.status === 403 && error?.error?.message === 'Forbidden' && (error?.error?.error_code === 'SC091' || error?.error?.error_code === 'SC023')) && this.eventBus.getUserInfo() && !error.request.headers.get('otp')) {
                this.eventBus.setIsMultisession(true);
                this.checkRedirection({goTo: 'login'});
            }
        });
        this.eventBus?.on('change_language', (data: any) => {
            this.translate.use(data);
            setTimeout(() => {
                this.ref.detectChanges()
            }, 1000)
        });
    }

    checkRedirection(micro: any): void {
        if (micro.goTo === 'login') {
            this.logOutService.revokeToken().subscribe();
            this.eventBus.setUserInfo(undefined);
        }
        this.ngZone.run(() => {
            this.router.navigate([micro.goTo]);
        });
    }

    reset(): void {
        this.idle.setIdle((this.inactivityTime * 0.7) - 5);
        this.idle.watch();
        this.timedOut = false;
    }

    closeModal(id: string, isSubmit?: boolean): void {
        this.eventBus.setUserInfo(undefined);
        if (isSubmit) {
            this.modalService.close(id);
        }
        if (id === 'inactivity-modal') {
            this.googleAnalyticsService.eventEmitter('Cerrar Sesion', '53', 'sesion_expirada', 'sesion_expirada', 'btn_entendido')
            this.checkRedirection({goTo: 'login'});
        }
    }

    listenForRefreshToken(secret: any): void {
        this.configService.config.token.value = secret.access_token;
        this.configService.config.token.type = secret.token_type?.charAt(0)?.toUpperCase() + secret.token_type?.substr(1)?.toLowerCase();
        this.configService.config.token.expire = secret.expires_in;
        if (secret.refresh_token) {
          this.configService.config.token.refresh = secret.refresh_token;
        }
        this.setTimeOutRefreshToken();
    }

    setTimeOutRefreshToken(): void {
        const expires: any = this.configService.config.token.expire; 
        const time = 700 * expires;
        setTimeout(() => {
          this.refreshToken();
        }, time);
    }

    refreshToken(): void {
        this.logOutService.refreshToken(this.configService.config.token.refresh).subscribe((res: any) => {
            if (res.access_token) {
                const userInfo = this.eventBus.getUserInfo();
                userInfo.secret = res;
                this.listenForRefreshToken(userInfo.secret);
                userInfo.secret.token_type = userInfo.secret.token_type?.charAt(0)?.toUpperCase() + userInfo.secret.token_type?.substr(1)?.toLowerCase();
                this.eventBus.setUserInfo(userInfo);
                this.eventBus.emit({name: 'userInfo_micro', data: userInfo});
            }
        }, () => {
            this.checkRedirection({goTo: 'login'});
        });
    }
}
