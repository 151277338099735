import { Component } from '@angular/core';
import {EventBusRootService} from "@com-bam/lib-micro-communication";

@Component({
  selector: 'app-root-users-registration',
  templateUrl: './users-registration.component.html'
})
export class UsersRegistrationComponent {
  constructor(private eventBus: EventBusRootService) {
  }
  changeLanguage(): void {
    this.eventBus.emit({name: 'change_language', data: sessionStorage.getItem('language')});
  }
}
