import {LifeCycles, registerApplication, start} from 'single-spa';
import {MICRO_FRONTEND} from './constants/general.config';

export function microsRegistering(eventBus: any): void {
    
    //Login Micro
    registerApplication({
        name: MICRO_FRONTEND.MICRO_LOGIN.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.MICRO_LOGIN.SRC),
        activeWhen: '/login',
        customProps: {
            EventBus: eventBus
        }
    });

    //User Recovery Micro
    registerApplication({
        name: MICRO_FRONTEND.MICRO_USER_RECOVERY.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.MICRO_USER_RECOVERY.SRC),
        activeWhen: '/user-recovery',
        customProps: {
            EventBus: eventBus
        }
    });

    //Users Registration Micro
    registerApplication({
        name: MICRO_FRONTEND.MICRO_USERS_REGISTRATION.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.MICRO_USERS_REGISTRATION.SRC),
        activeWhen: '/users-registration',
        customProps: {
            EventBus: eventBus
        }
    });

    //Home Micro
    registerApplication({
        name: MICRO_FRONTEND.MICRO_HOME.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.MICRO_HOME.SRC),
        activeWhen: '/home',
        customProps: {
            EventBus: eventBus
        }
    });

    //Password Recovery Micro
    registerApplication({
        name: MICRO_FRONTEND.MICRO_PWD_RECOVERY.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.MICRO_PWD_RECOVERY.SRC),
        activeWhen: '/pwd-recovery',
        customProps: {
            EventBus: eventBus
        }
    });

    //Change password micro 
    registerApplication({
        name: MICRO_FRONTEND.CHANGE_PASSWORD.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.CHANGE_PASSWORD.SRC),
        activeWhen: '/change-password',
        customProps: {
            EventBus: eventBus
        }
    });

    //Create User micro 
    registerApplication({
        name: MICRO_FRONTEND.CHANGE_CREATE_USERS.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.CHANGE_CREATE_USERS.SRC),
        activeWhen: '/create-users',
        customProps: {
            EventBus: eventBus
        }
    });

    //Roles
    registerApplication({
        name: MICRO_FRONTEND.MICRO_ROLES.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.MICRO_ROLES.SRC),
        activeWhen: '/roles',
        customProps: {
            EventBus: eventBus
        }
    });
    
    //Products groups micro 
    registerApplication({
        name: MICRO_FRONTEND.PRODUCTS_GROUP.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.PRODUCTS_GROUP.SRC),
        activeWhen: '/products-group',
        customProps: {
            EventBus: eventBus
        }
    });

    //Approval Flow micro
    registerApplication({
        name: MICRO_FRONTEND.APPROVAL_FLOW.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.APPROVAL_FLOW.SRC),
        activeWhen: '/approval-flow',
        customProps: {
            EventBus: eventBus
        }
    });

    //Pending Approvals Micro
    registerApplication({
        name: MICRO_FRONTEND.PENDING_APPROVALS.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.PENDING_APPROVALS.SRC),
        activeWhen: '/pending-approvals',
        customProps: {
            EventBus: eventBus
        }
    });

    //Consolidated products Micro
    registerApplication({
        name: MICRO_FRONTEND.CONSOLIDATED_PRODUCTS.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.CONSOLIDATED_PRODUCTS.SRC),
        activeWhen: '/consolidated',
        customProps: {
            EventBus: eventBus
        }
    });

    //Loans payment micro
    registerApplication({
        name: MICRO_FRONTEND.LOANS_PAYMENT.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.LOANS_PAYMENT.SRC),
        activeWhen: '/loans-payment',
        customProps: {
            EventBus: eventBus
        }
    });

    //Ach Transfer micro
    registerApplication({
        name: MICRO_FRONTEND.ACH_TRANSFER.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.ACH_TRANSFER.SRC),
        activeWhen: '/ach-transfer',
        customProps: {
            EventBus: eventBus
        }
    });

    //Transfer Own Accounts Micro
    registerApplication({
        name: MICRO_FRONTEND.TRANSFER_OWN_ACCOUNTS.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.TRANSFER_OWN_ACCOUNTS.SRC),
        activeWhen: '/transfer',
        customProps: {
            EventBus: eventBus
        }
    });

    //Cheques Micro
    registerApplication({
        name: MICRO_FRONTEND.MICRO_CHEQUES.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.MICRO_CHEQUES.SRC),
        activeWhen: '/cheques',
        customProps: {
            EventBus: eventBus
        }
    });

    registerApplication({
        name: MICRO_FRONTEND.MICRO_FAVORITES.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.MICRO_FAVORITES.SRC),
        activeWhen: '/favorites',
        customProps: {
            EventBus: eventBus
        }
    });

    //BancaSAT micro
    registerApplication({
        name: MICRO_FRONTEND.MICRO_BANCASAT.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.MICRO_BANCASAT.SRC),
        activeWhen: '/bancaSAT',
        customProps: {
            EventBus: eventBus
        }
    });
    
    registerApplication({
        name: MICRO_FRONTEND.TRANSFER_THIRDS_BAM.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.TRANSFER_THIRDS_BAM.SRC),
        activeWhen: '/transfer-bam',
        customProps: {
            EventBus: eventBus
        }
    });

    registerApplication({
        name: MICRO_FRONTEND.DECLARAGUATE_PAYMENT.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.DECLARAGUATE_PAYMENT.SRC),
        activeWhen: '/declaraguate',
        customProps: {
            EventBus: eventBus
        }
    });

    registerApplication({
        name: MICRO_FRONTEND.TDC_PAYMENT.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.TDC_PAYMENT.SRC),
        activeWhen: '/tdc-payment',
        customProps: {
            EventBus: eventBus
        }
    });
    // Payroll Micro
    registerApplication({
        name: MICRO_FRONTEND.MICRO_PAYROLL.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.MICRO_PAYROLL.SRC),
        activeWhen: '/payroll',
        customProps: {
            EventBus: eventBus
        }
    });

    // Payroll Micro
    registerApplication({
        name: MICRO_FRONTEND.MICRO_TRANSFER_OTHER_BANKS.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.MICRO_TRANSFER_OTHER_BANKS.SRC),
        activeWhen: '/transfer-other',
        customProps: {
            EventBus: eventBus
        }
    });
    // TransferInternational
    registerApplication({
        name: MICRO_FRONTEND.MICRO_TRANSFER_INTERNATIONAL.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.MICRO_TRANSFER_INTERNATIONAL.SRC),
        activeWhen: '/transfer-international',
        customProps: {
            EventBus: eventBus
        }
    });
    // WithdrawDepositAccounts
    registerApplication({
        name: MICRO_FRONTEND.MICRO_WITHDRAW_DEPOSIT_ACCOUNTS.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.MICRO_WITHDRAW_DEPOSIT_ACCOUNTS.SRC),
        activeWhen: '/withdraw-deposit-accounts',
        customProps: {
            EventBus: eventBus
        }
    });
    // Payment services
    registerApplication({
        name: MICRO_FRONTEND.MICRO_PAYMENT_SERVICES.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.MICRO_PAYMENT_SERVICES.SRC),
        activeWhen: '/services',
        customProps: {
            EventBus: eventBus
        }
    });

     // cash_advance micro
     registerApplication({
        name: MICRO_FRONTEND.MICRO_CASH_ADVANCE.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.MICRO_CASH_ADVANCE.SRC),
        activeWhen: '/cash-advance',
        customProps: {
            EventBus: eventBus
        }
    });

     // manage_limits_tdc micro
     registerApplication({
        name: MICRO_FRONTEND.MICRO_LIMITS_TDC.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.MICRO_LIMITS_TDC.SRC),
        activeWhen: '/tdc',
        customProps: {
            EventBus: eventBus
        }
    });


     // tdd micro
     registerApplication({
        name: MICRO_FRONTEND.MICRO_TDD.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.MICRO_TDD.SRC),
        activeWhen: '/tdd',
        customProps: {
            EventBus: eventBus
        }
    });
    
    //Easy cash micro
    registerApplication({
        name: MICRO_FRONTEND.MICRO_EASY_CASH.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.MICRO_EASY_CASH.SRC),
        activeWhen: '/easy-cash',
        customProps: {
            EventBus: eventBus
        }
    });

    //Configure cards micro
    registerApplication({
        name: MICRO_FRONTEND.MICRO_CONFIGURE_CARDS.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.MICRO_CONFIGURE_CARDS.SRC),
        activeWhen: '/configure-cards',
        customProps: {
            EventBus: eventBus
        }
    });

    //custom report micro
    registerApplication({
        name: MICRO_FRONTEND.MICRO_CUSTOM_REPORT.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.MICRO_CUSTOM_REPORT.SRC),
        activeWhen: '/custom-report',
        customProps: {
            EventBus: eventBus
        }
    });

    //consult transaction micro
    registerApplication({
        name: MICRO_FRONTEND.MICRO_CONSULT_TRANSACTION.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.MICRO_CONSULT_TRANSACTION.SRC),
        activeWhen: '/consult-transaction',
        customProps: {
            EventBus: eventBus
        }
    });

    //Account statements
    registerApplication({
        name: MICRO_FRONTEND.MICRO_ACCOUNT_STATEMENTS.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.MICRO_ACCOUNT_STATEMENTS.SRC),
        activeWhen: '/account-statements',
        customProps: {
            EventBus: eventBus
        }
    });

    //consult massive micro
    registerApplication({
        name: MICRO_FRONTEND.MICRO_CONSULT_MASSIVE.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.MICRO_CONSULT_MASSIVE.SRC),
        activeWhen: '/consult-massive',
        customProps: {
            EventBus: eventBus
        }
    });

      //consult scheduled micro
      registerApplication({
        name: MICRO_FRONTEND.MICRO_CONSULT_SCHEDULED.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.MICRO_CONSULT_SCHEDULED.SRC),
        activeWhen: '/consult-scheduled',
        customProps: {
            EventBus: eventBus
        }
    });

    //consult exchange micro
    registerApplication({
        name: MICRO_FRONTEND.MICRO_CONSULT_EXCHANGE.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.MICRO_CONSULT_EXCHANGE.SRC),
        activeWhen: '/consult-exchange-rate',
        customProps: {
            EventBus: eventBus
        }
    });

    
    //travel notice
    registerApplication({
        name: MICRO_FRONTEND.MICRO_TRAVEL_NOTICE.NAME,
        app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.MICRO_TRAVEL_NOTICE.SRC),
        activeWhen: '/travel-notification',
        customProps: {
            EventBus: eventBus
        }
    });


            //micro vinculacion digital
            registerApplication({
                name: MICRO_FRONTEND.LOAD_INFORMATION_VD_CONFIG.NAME,
                app: (): Promise<LifeCycles> => (window as any).System.import(MICRO_FRONTEND.LOAD_INFORMATION_VD_CONFIG.SRC),
                activeWhen: 'digital-bonding/load',
                customProps: {
                    EventBus: eventBus
                }
            });
    
    

    start();
}
