import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root-vinculacion-load',
  templateUrl: './vinculacion-load.component.html'
})
export class VinculacionLoadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
