<header class="d-flex justify-content-between px-3">
  <section class="d-flex align-items-center">
      <div class="menu-action-container d-flex flex-column align-items-center"
          (click)="open()">
          <i class="{{menuIsOpen ? 'icon-arrow-angle-double-left' : 'icon-arrow-angle-double-right'}} d-none d-xl-block header-btn"></i>
          <i class="{{!menuIsOpen ? 'icon-arrow-angle-double-left' : 'icon-arrow-angle-double-right'}} d-none d-md-block d-xl-none header-btn"></i>
          <i class="{{menuIsOpen ? 'icon-menu' : 'icon-error'}} d-block d-md-none header-btn"></i>
          <span class="xsmall color_back"  > {{"menu.menu" | translate }} </span>
      </div>
      <div class="bam-icon-container text-center">
          <i class="icon-logos-Bam-primario-positivo action-logo" (click)="clickIcon()"></i>
      </div>
      <div class="vertical-separator mx-2 d-none d-xl-block"></div>
      <div class="h5-light px-4 d-none d-xl-block color_back" (click)="clickIcon()" >
        <span class="xsmall">{{ "menu.title" | translate }}</span>
      </div>
  </section>

  <section class="d-flex align-items-center">
      <div class="help-section d-flex align-items-center">
          <i class="icon-help mx-2"></i>
          <span class="small d-none d-md-block color_back" (click)="clickIconHelp()">{{"menu.help" | translate }}</span>
      </div>
      <div class="vertical-separator mx-1 mx-md-3"></div>
      <div class="company-section d-flex align-items-center">
          <i class="icon-store mx-2"></i>
          <span class="small d-none d-md-block color_back" (click)="clickIconTest()">{{userInfo?.economic_group_name}}</span>
      </div>
      <div class="vertical-separator mx-1 mx-md-3"></div>
      <div class="user-section d-flex align-items-center">
          <input type="checkbox" id="btnControl" [(ngModel)]="showHeaderMenu"/>
          <label for="btnControl" class="d-flex align-items-center">
              <div class="arrow-menu-espe" (click)="clickIconArrow()">
                  <i class="icon-user mx-2"></i>
                  <span class="small d-none d-xl-block color_back">{{userInfo?.given_name | titlecase}} {{userInfo?.family_name | titlecase}}</span>
                  <i class="icon-arrow2-down mx-2 d-none d-xl-block"></i>
              </div>
              <div *ngIf="!showHeaderMenu" class="tooltipuser d-none d-xl-block">
                  <span class="tooltipusertext">{{"menu.datos" | translate }}</span>
                  <div class="peak-tooltip"></div>
              </div>
              <div *ngIf="items[1] && showHeaderMenu" class="tooltipMenu">
                  <div *ngFor="let item of items[1]" class="p-3 d-flex align-items-center"
                      (click)="item.command()">
                      <i class="{{item.icon}} color2"></i>
                      <span class="px-3 small">{{item.label}}</span>
                  </div>
                  <div (click)="changeLanguage()" class="p-3 d-flex align-items-center">
                    <i class="icon-international color2"></i>
                    <span class="px-3 small">{{'languageModal.title' | translate }}</span>
                </div>
              </div>
          </label>
      </div>
  </section>
</header>

<div class="d-flex" (click)="closeHeaderMenu()">
  <div class="menu-option d-none d-lg-flex d-xl-flex">
      <div class="{{menuIsOpen ? 'menu-container' : 'menu-container-close'}} p-0">
          <div class="flex-fill-menu">
              <div *ngFor="let item of items[0]; let i = index"
                  class="{{ item.isActive ? 'menu-option-selected' : ''}} ">
                  <div class="menu-row row d-flex justify-content-center align-items-center px-3 hover-menu-row {{ menuIsOpen ? '' : 'menu-row-close'}}"
                      (click)="menuSelect(items[0], i)" role="button">
                      <div class="menu-option-icon d-flex justify-content-center align-items-center">
                          <span><i class="pi {{ item.icon }}"></i></span>
                      </div>
                      <div class="menu-option-label d-flex align-items-center p-0" *ngIf="menuIsOpen">
                          <span>{{ item.label }}</span>
                      </div>
                      <div class="menu-option-arrow d-flex justify-content-center align-items-center" *ngIf="menuIsOpen">
                          <span>
                              <i class="pi {{item.items.length > 0 ? item.isActive && item.openOptions ? 'icon-arrow2-up' : 'icon-arrow2-down' : ''}}"></i>
                          </span>
                      </div>
                      <span class="tooltiptext" *ngIf="!menuIsOpen">{{item.label}}</span> 
                  </div>
                  <div *ngIf="menuIsOpen && item.isActive && item?.items?.length > 0 && item.openOptions">
                      <div *ngFor="let subItem of item.items let j = index">
                          <div (click)="itemSelect(item.items, j)" role="button">
                              <div class="container-sub-menu row d-flex justify-content-center align-items-center">
                                  <div class="menu-option-icon d-flex justify-content-center align-items-center">
                                      <span>
                                          <i class="pi {{ subItem.icon }}"></i>
                                      </span>
                                  </div>
                                  <div class="menu-option-label sub-menu-row d-flex align-items-center">
                                      <span>{{ subItem.label }}</span>
                                  </div>
                                  <div class="menu-option-arrow d-flex justify-content-center align-items-center">
                                      <span>
                                          <i class="pi {{subItem?.items?.length > 0 ? subItem.isActive ? 'icon-arrow2-up' : 'icon-arrow2-down' : ''}}"></i>
                                      </span>
                                  </div>
                              </div>
                              <div *ngIf="menuIsOpen && subItem.isActive && subItem?.items?.length > 0">
                                  <div *ngFor="let subItemLv2 of subItem.items">
                                      <div class="container-sub-menu row d-flex justify-content-center align-items-center"
                                          (click)="subItemLv2.command()" role="button">
                                          <div class="menu-option-sub-icon d-flex justify-content-center align-items-center">
                                              <span>
                                                  <i class="pi {{ subItemLv2.icon }}"></i>
                                              </span>
                                          </div>
                                          <div class="menu-option-label sub-menu-row d-flex align-items-center pr-0">
                                              <span class="sub-label-text">{{ subItemLv2.label }}</span>
                                          </div>
                                          <div class="menu-option-arrow d-flex justify-content-center align-items-center">
                                              <span>
                                                  <i class="pi {{subItemLv2?.items?.length > 0 ? 'icon-arrow2-down' : ''}}"></i>
                                              </span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="flex-fill-bottom m-3" *ngIf="menuIsOpen">
              <div id="btnLogOut"
                  class="button-container d-flex align-items-center justify-content-center p-2"
                  (click)="logoutEvent()">
                  <h6> {{"menu.close" | translate}} </h6>
                  <i class="icon-quit"></i>
              </div>
          </div>
          <div class="flex-fill-bottom m-3 d-flex justify-content-center align-content-center" *ngIf="!menuIsOpen">
              <span>
                  <i class="pi icon-quit" (click)="logoutEvent()" role="button"></i>
              </span>
          </div>
      </div>
  </div>
  <div class="menu-md-option d-none d-md-flex d-xl-none d-lg-none">
      <div class="menu-container {{menuIsOpen ? '' : 'menu-container-open'}} p-0">
          <div class="flex-fill-menu" *ngIf="menuIsOpen">
              <div *ngFor="let item of items[0]; let i = index">
                  <div class="menu-row row d-flex justify-content-center align-items-center px-3 {{ item.isActive ? 'menu-option-selected' : ''}}"
                      (click)="menuSelect(items[0], i); menuIsOpen = false;" role="button">
                      <div class="menu-option-icon d-flex justify-content-center align-items-center">
                          <span>
                              <i class="pi {{ item.icon }}"></i>
                          </span>
                      </div>
                  </div>
              </div>
          </div>
          <div class="flex-fill-bottom m-3 d-flex justify-content-center align-content-center" *ngIf="menuIsOpen">
              <span>
                  <i class="pi icon-quit" (click)="logoutEvent()" role="button"></i>
              </span>
          </div>
          <div class="flex-fill-menu" *ngIf="!menuIsOpen">
              <div *ngFor="let item of items[0]; let i = index" class="{{ item.isActive ? 'menu-option-selected' : ''}}">
                  <div class="menu-row row d-flex justify-content-center align-items-center px-3"
                      (click)="menuSelect(items[0], i)" role="button">
                      <div class="menu-option-icon d-flex justify-content-center align-items-center">
                          <span>
                              <i class="pi {{ item.icon }}"></i>
                          </span>
                      </div>
                      <div class="menu-option-label d-flex align-items-center p-0">
                          <span>{{ item.label }}</span>
                      </div>
                      <div class="menu-option-arrow d-flex justify-content-center align-items-center">
                          <span>
                              <i class="pi {{item.items.length > 0 ? item.isActive && item.openOptions ? 'icon-arrow2-up' : 'icon-arrow2-down' : ''}}"></i>
                          </span>
                      </div>
                  </div>
                  <div *ngIf="!menuIsOpen && item.isActive && item?.items?.length > 0 && item.openOptions">
                      <div *ngFor="let subItem of item.items let j = index">
                          <div (click)="itemSelect(item.items, j)" role="button">
                              <div class="container-sub-menu row d-flex justify-content-center align-items-center">
                                  <div class="menu-option-icon d-flex justify-content-center align-items-center">
                                      <span>
                                          <i class="pi {{ subItem.icon }}"></i>
                                      </span>
                                  </div>
                                  <div class="menu-option-label sub-menu-row d-flex align-items-center">
                                      <span>{{ subItem.label }}</span>
                                  </div>
                                  <div class="menu-option-arrow d-flex justify-content-center align-items-center">
                                      <span>
                                          <i class="pi {{subItem?.items?.length > 0 ? subItem.isActive ? 'icon-arrow2-up' : 'icon-arrow2-down' : ''}}"></i>
                                      </span>
                                  </div>
                              </div>
                              <div *ngIf="!menuIsOpen && subItem.isActive && subItem?.items?.length > 0">
                                  <div *ngFor="let subItemLv2 of subItem.items">
                                      <div class="container-sub-menu row d-flex justify-content-center align-items-center"
                                          (click)="subItemLv2.command()" role="button">
                                          <div class="menu-option-sub-icon d-flex justify-content-center align-items-center">
                                              <span>
                                                  <i class="pi {{ subItemLv2.icon }}"></i>
                                              </span>
                                          </div>
                                          <div class="menu-option-label sub-menu-row d-flex align-items-center pr-0">
                                              <span class="sub-label-text">{{ subItemLv2.label }}</span>
                                          </div>
                                          <div class="menu-option-arrow d-flex justify-content-center align-items-center">
                                              <span>
                                                  <i class="pi {{subItemLv2?.items?.length > 0 ? 'icon-arrow2-down' : ''}}"></i>
                                              </span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="flex-fill-bottom m-3" *ngIf="!menuIsOpen">
              <div id="btnLogOut"
                  class="button-container d-flex align-items-center justify-content-center p-2"
                  (click)="logoutEvent()">
                  <h6> {{"menu.close" | translate}} </h6>
                  <i class="icon-quit"></i>
              </div>
          </div>
      </div>
  </div>
  <div class="menu-sm-option d-flex d-md-none d-xl-none d-lg-none">
      <div class="menu-container p-0" *ngIf="!menuIsOpen">
          <div class="flex-fill-menu" *ngIf="!menuIsOpen">
              <div *ngFor="let item of items[0]; let i = index" class="{{ item.isActive ? 'menu-option-selected' : ''}}">
                  <div class="menu-row row d-flex justify-content-center align-items-center px-3 mx-0"
                      (click)="menuSelect(items[0], i)" role="button">
                      <div class="menu-option-icon d-flex justify-content-center align-items-center">
                          <span>
                              <i class="pi {{ item.icon }}"></i>
                          </span>
                      </div>
                      <div class="menu-option-label d-flex align-items-center p-0">
                          <span>{{ item.label }}</span>
                      </div>
                      <div class="menu-option-arrow d-flex justify-content-center align-items-center">
                          <span>
                              <i class="pi {{item.items.length > 0 ? item.isActive && item.openOptions ? 'icon-arrow2-up' : 'icon-arrow2-down' : ''}}"></i>
                          </span>
                      </div>
                  </div>
                  <div *ngIf="!menuIsOpen && item.isActive && item?.items?.length > 0 && item.openOptions">
                      <div *ngFor="let subItem of item.items let j = index">
                          <div (click)="itemSelect(item.items, j)" role="button">
                              <div class="container-sub-menu row d-flex justify-content-center align-items-center">
                                  <div class="menu-option-icon d-flex justify-content-center align-items-center">
                                      <span>
                                          <i class="pi {{ subItem.icon }}"></i>
                                      </span>
                                  </div>
                                  <div class="menu-option-label sub-menu-row d-flex align-items-center">
                                      <span>{{ subItem.label }}</span>
                                  </div>
                                  <div class="menu-option-arrow d-flex justify-content-center align-items-center">
                                      <span>
                                          <i class="pi {{subItem?.items?.length > 0 ? subItem.isActive ? 'icon-arrow2-up' : 'icon-arrow2-down' : ''}}"></i>
                                      </span>
                                  </div>
                              </div>
                              <div *ngIf="!menuIsOpen && subItem.isActive && subItem?.items?.length > 0">
                                  <div *ngFor="let subItemLv2 of subItem.items">
                                      <div class="container-sub-menu row d-flex justify-content-center align-items-center"
                                          (click)="subItemLv2.command()" role="button">
                                          <div class="menu-option-sub-icon d-flex justify-content-center align-items-center">
                                              <span>
                                                  <i class="pi {{ subItemLv2.icon }}"></i>
                                              </span>
                                          </div>
                                          <div class="menu-option-label sub-menu-row d-flex align-items-center pr-0">
                                              <span class="sub-label-text">{{ subItemLv2.label }}</span>
                                          </div>
                                          <div class="menu-option-arrow d-flex justify-content-center align-items-center">
                                              <span>
                                                  <i class="pi {{subItemLv2?.items?.length > 0 ? 'icon-arrow2-down' : ''}}"></i>
                                              </span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="flex-fill-bottom m-3">
              <div id="btnLogOut"
                  class="button-container d-flex align-items-center justify-content-center p-2"
                  (click)="logoutEvent()">
                  <h6> {{"menu.close" | translate}} </h6>
                  <i class="icon-quit"></i>
              </div>
          </div>
      </div>
  </div>
  <div class="{{ menuIsOpen ? 'dashboard-container' : 'dashboard-container-close'}} dashboard-container d-flex flex-column justify-content-between">
      <div class="p-3 px-md-4">
          <ng-content></ng-content>
      </div>
      <div>
          <app-root-footer></app-root-footer>
      </div>
  </div>
</div>


<root-modal-content id="modal-refresh-session" 
  [columnStyles]="'col-11'"
  (modalSubmit)="closeSession()">
  <div class="text-center mt-4 mt-md-0">
      <i class="icon-hand"></i>
      <h5 class="text-center mt-4 mb-3">{{ 'logout.refreshPage.title' | translate }}</h5>
      <span class="small px-xl-3">{{ 'logout.refreshPage.desc' | translate }}</span>
  </div>
  <div class="d-flex row justify-content-center flex-column-reverse flex-md-row mx-0 mt-4 mb-3">
      <div class="col-12 col-md-3 py-2 mx-md-2 btn-container gray-btn text-center"
       (click)="closeModal()">
          <h6>{{'general.no' | translate | uppercase }}</h6>
      </div>
      <div class="col-12 col-md-3 py-2 mx-md-2 mb-3 mb-md-0 btn-container yellow-btn text-center"
          (click)="closeSession()">
          <h6>{{'general.yes' | translate | uppercase }}</h6>
      </div>
  </div>
</root-modal-content>
<root-modal-content id="ROLE_PERMISIONS_ERROR" 
  [columnStyles]="'col-11'"
  (modalSubmit)="goToHome()"
  (modalClosed)="goToHome()">
  <div class="text-center mt-4 mt-md-0">
    <i class="icon-error red-circle"></i>
      <h5 class="text-center mt-4 mb-3">{{ 'menu.errorPermissions.title' | translate }}</h5>
      <span class="small px-xl-3">{{ 'menu.errorPermissions.description' | translate }}</span>
  </div>
  <div class="d-flex row justify-content-center flex-column-reverse flex-md-row mx-0 mt-4 mb-3">
      <div class="col-12 col-md-3 py-2 mx-md-2 mb-3 mb-md-0 btn-container yellow-btn text-center rounded-pill pointer"
          (click)="goToHome()">
          <h6>{{'general.understood' | translate | uppercase }}</h6>
      </div>
  </div>
</root-modal-content>
<root-modal-content id="modal-error" [columnStyles]="'col-11'">
  <div class="text-center mt-4 mt-md-0">   
      <i class = "icon-error red-circle"></i>     
      <h5 class="text-center mt-4 mb-3 title">{{ 'menu.e09.title' | translate }}</h5>
      <p class = "small text-h6"> {{ 'menu.e09.description' | translate }} </p>
  </div> 

  <div class="d-flex row justify-content-center flex-column-reverse flex-md-row mx-0 mt-4 mb-3">
      <div class="col-6 col-md-3 py-2 mx-md-2 btn-principal gray-btn yellow-btn text-center rounded-pill pointer"
       (click)="closeSession()">
          <h6>{{'general.understood' | translate | uppercase }}</h6>
      </div>
  </div>
</root-modal-content>
<sve-language-modal
                          [showLanguage]="logoutService.showChangeLanguage"
                          (showLanguageEmit)="logoutService.showChangeLanguage = false;">
</sve-language-modal>
<root-modal-content id="modal-error-role" 
  [columnStyles]="'col-md-6'"
  (modalSubmit)="logoutEvent()">

  <div class="text-center mt-4 mt-md-0 btn-container"> 
      <i class="icon-hand"></i>
      <h5 class="text-center mt-4 mb-3">{{ 'menu.e166.title' | translate }}</h5>
</div> 
  <div class="d-flex row justify-content-center flex-column-reverse flex-md-row mx-0 mt-4 mb-3">
      <div class="col-6 col-md-3 py-2 mx-md-2 btn-container gray-btn text-center"
       (click)="closeModal()">
          <h6>{{'general.understood' | translate | uppercase }}</h6>
      </div>
  </div>
</root-modal-content>