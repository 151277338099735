import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {EventBusRootService} from "@com-bam/lib-micro-communication";

@Component({
  selector: 'sve-language-modal',
  templateUrl: './language-modal.component.html',
  styleUrls: ['./language-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LanguageModalComponent implements OnInit {
  @Input() showLanguage = false;
  @Output() showLanguageEmit = new EventEmitter();
  formLanguage: FormGroup = new FormGroup({
    language: new FormControl('', [Validators.required])
  });
  disabledButton = true;

  constructor(public translate: TranslateService, private eventBus: EventBusRootService) {
    this.formLanguage.get('language').setValue(sessionStorage.getItem('language') || 'es')
    if (sessionStorage.getItem('language')) {
      this.translate.use(sessionStorage.getItem('language') || '');
    } else {
      sessionStorage.setItem('language', 'es');
      this.translate.use('es');
    }
  }

  ngOnInit(): void {
    this.formLanguage?.valueChanges?.subscribe((change: any) => {
      this.disabledButton = false;
    })
  }

  selectLanguage(): void {
    if (!this.disabledButton) {
      sessionStorage.setItem('language', this.formLanguage.get('language').value);
      this.eventBus.emit({name: 'change_language', data: this.formLanguage.get('language').value});
      this.showLanguageEmit.emit();
    }
  }

  closeAccessModal(): void {
    this.showLanguageEmit.emit();
    this.formLanguage.reset()
  }
}
