import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { HeaderMenuComponent } from './header-menu.component';
import { FooterModule } from '../footer/footer.module';
import { CommonModule } from '@angular/common';
import { ModalContentModule } from '../modal-content/modal-content.module';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    HeaderMenuComponent
  ],
    imports: [
        FooterModule,
        CommonModule,
        ModalContentModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            defaultLanguage: sessionStorage.getItem('language') || 'es'
        }),
        FormsModule,
        ReactiveFormsModule
    ],
  exports: [ HeaderMenuComponent ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
})
export class HeaderMenuModule { }
