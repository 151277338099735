export const hostLoginConfig = 'https://ems.devbam.com/micros/micro-login';
export const hostHomeConfig = 'https://ems.devbam.com/micros/micro-home';
export const hostUserRecoveryConfig = 'https://ems.devbam.com/micros/micro-user-recovery';
export const hostPwdRecoveryConfig = 'https://ems.devbam.com/micros/micro-pwd-recovery';
export const hostUsersRegistrationConfig = 'https://ems.devbam.com/micros/micro-users-registration';
export const hostChangePasswordConfig = 'https://ems.devbam.com/micros/micro-change-pwd';
export const hostCreateUsersConfig = 'https://ems.devbam.com/micros/micro-create-users';
export const hostRolesConfig = 'https://ems.devbam.com/micros/micro-roles';
export const hostProductsGroupConfig = 'https://ems.devbam.com/micros/micro-products-group';
export const hostApprovalFlowConfig = 'https://ems.devbam.com/micros/micro-approval-flow';
export const hostPendingApprovalsConfig = 'https://ems.devbam.com/micros/micro-pending-approvals';
export const hostConsolidatedProductsConfig = 'https://ems.devbam.com/micros/micro-consolidated-products';
export const hostLoansPaymentConfig = 'https://ems.devbam.com/micros/micro-loans-payment';
export const hostTransferOwnAccountsConfig = 'https://ems.devbam.com/micros/micro-transfer-own-accounts';
export const hostChequesConfig = 'https://ems.devbam.com/micros/micro-cheques';
export const hostFavoritesConfig = 'https://ems.devbam.com/micros/micro-favorites';
export const hostAchTransferConfig = 'https://ems.devbam.com/micros/micro-ach-transfer';
export const hostBancaSatConfig = 'https://ems.devbam.com/micros/micro-banca-sat';
export const hostTransferThirdsBamConfig = 'https://ems.devbam.com/micros/micro-transfer-thirds-bam';
export const hostDeclaraguatePaymentConfig = 'https://ems.devbam.com/micros/micro-declaraguate-payment';
export const hostTdcPaymentConfig = 'https://ems.devbam.com/micros/micro-tdc-payment';
export const hostPayrollConfig = 'https://ems.devbam.com/micros/micro-payroll';
export const hostTransferInternationalConfig = 'https://ems.devbam.com/micros/micro-transfer-international';
export const hostPaymentServicesConfig = 'https://ems.devbam.com/micros/micro-payment-services';
export const hostTransferOtherBanksConfig = 'https://ems.devbam.com/micros/micro-transfer-other-banks';
export const hostCashAdvanceConfig = 'https://ems.devbam.com/micros/micro-cash-advance';
export const hostTDDConfig = 'https://ems.devbam.com/micros/micro-tdd';
export const hostEasyCashConfig = 'https://ems.devbam.com/micros/micro-easy-cash';
export const hostWithdrawDepositAccountsConfig = 'https://ems.devbam.com/micros/micro-withdraw-deposit-accounts';
export const hostTDCconfig = 'https://ems.devbam.com/micros/micro-limits-tdc';
export const hostConfigureCardsconfig = 'https://ems.devbam.com/micros/micro-configure-cards';
export const hostCustomReportConfig = 'https://ems.devbam.com/micros/micro-custom-report';
export const hostConsultTransactionConfig = 'https://ems.devbam.com/micros/micro-consult-transaction';
export const hostAccountStatementsConfig = 'https://ems.devbam.com/micros/micro-account-statements'
export const hostConsultMassiveConfig = 'https://ems.devbam.com/micros/micro-consult-massive';
export const hostConsultScheduledConfig = 'https://ems.devbam.com/micros/micro-consult-scheduled';
export const hostConsultExchangeConfig = 'https://ems.devbam.com/micros/micro-consult-exchange-rate';
export const hostTravelNoticeConfig = 'https://ems.devbam.com/micros/micro-travel-notice';
export const hostLoadInformationVDConfig = 'https://ems.devbam.com/micros/micro-load-information-vd';
