<section class="container-search-modal">
    <div class="container-principal col-12 col-md-7 col-lg-4">
        <div class="container">
            <div class="col-12 col-12 d-flex justify-content-end pb-2">
                <i class="pi pi-times" role="button" (click)="closeAccessModal()"></i>
            </div>
            <h4>{{ 'languageModal.title' | translate }}</h4>
            <div class="small pb-4">{{ 'languageModal.subTitle' | translate }}</div>
            <div class="container-bank">
                <form [formGroup]="formLanguage">
                    <div class="custom-control custom-radio mb-2">
                        <input id="spanish" type="radio" class="custom-control-input" formControlName="language" value="es" name="language">
                        <label class="custom-control-label"  for="spanish">{{ 'languageModal.spanish' | translate }}<i class="icon-ok"></i></label>
                    </div>
                    <div class="custom-control custom-radio mb-2">
                        <input id="english" type="radio" class="custom-control-input" formControlName="language" value="en" name="language">
                        <label class="custom-control-label" for="english">{{ 'languageModal.english' | translate }}<i class="icon-ok"></i></label>
                    </div>
                    <div class="col-12 d-flex justify-content-center mt-5">
                        <front-lib-button class="w-btn"
                                          id="btn-change-language"
                                          label="{{'languageModal.saveBtn' | translate }}"
                                          [type]="'primary-yellow'"
                                          [disabled]="disabledButton || false"
                                          (clickBtn)="selectLanguage()">
                        </front-lib-button>
                    </div>
                    <div class="col-12 d-flex justify-content-center mt-2 btn-2">
                        <front-lib-button class="cancel"
                                          id="btn-cancel-language"
                                          label="{{'languageModal.cancel' | translate }}"
                                          style="text-decoration: underline;"
                                          [type]="'primary-transparent-border'"
                                          [fontStyle]="'xsmall'"
                                          (clickBtn)="closeAccessModal()">
                        </front-lib-button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>