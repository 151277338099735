import {NgZone} from '@angular/core';
import {MenuService} from 'src/app/services/menu/menu.service';
import {ConsolidatedProductsComponent} from "../../containers/consolidated-products/consolidated-products.component";

export class menuConfig {

    constructor(public menuService: MenuService) {
    }

    getMenu(
        router,
        singleSpaApplicationService,
        ngZone: NgZone, googleAnalyticsService: any, res: any): any {
        let lateralMenu = [];
        let headerOptions = [];
        this.menuService?.resetRoutesList();
        let menu_data: Array<any> = res.result_data;
        this.menuService?.addRoute('/home');
        
        menu_data?.forEach((level1: any) => {
            let itemsLevel = []
            let commandLevel1 = null;
            let commandLevel2 = null;
            this.menuService?.addRoute('/consult-scheduled/executed');
           
            if (level1?.name === 'Aprobaciones pendientes' || level1?.name === 'Pago de nómina' ) {
                commandLevel1 = this.commandBuilder(ngZone, googleAnalyticsService, router, level1.id, level1.name, level1.name, level1.subMenus[0].key_route, level1.name);
                this.menuService?.addRoute(level1.subMenus[0].key_route);
                if (level1.subMenus.length > 1) {
                    this.menuService?.addRoute(level1.subMenus[1].key_route);
                   
                }
            } else {
                if (level1.subMenus) {
                    level1.subMenus?.forEach((level2: any) => {
                        let itemsSubMenu1 = [];
                        if (level2.name === 'Saldos consolidados') {
                            this.menuService?.addRoute('/consolidated/movements/accounts');
                            this.menuService?.addRoute('/consolidated/movements/credit-cards');
                            this.menuService?.addRoute('/consolidated/movements/loans');
                        }
                        if(level2.subMenus){
                            if (level1?.name === 'Configurar' || level1?.name === 'Transferir' || (level1?.name === 'Pagar' && level2?.name === 'Tarjetas de crédito' ) || (level1?.name === 'Administrar productos' && level2?.name === 'Aviso de viaje' )  ){
                                level2.subMenus?.forEach(element => {
                                    this.menuService?.addRoute(element?.key_route);
                                });
                                commandLevel2 = this.commandBuilder(ngZone, googleAnalyticsService, router, level2.id, level1.name, level2.name, level2?.subMenus[0]?.key_route, level2.name)
                            } else {
                                level2.subMenus?.forEach((level3: any) => {
                                    if (level3.name === 'Declarar Cheques') {
                                        let commandLevel3 = this.commandBuilder(ngZone, googleAnalyticsService, router, level3?.id, level1.name, level2.name, level3?.subMenus[0].key_route, level3?.name)
                                        itemsSubMenu1.push(this.levelStructure(level3?.name, level3?.icon, level3?.subMenus[0].key_route, null, commandLevel3))
                                        level3.subMenus.forEach(itemLevel3 => {
                                            this.menuService?.addRoute(itemLevel3.key_route);
                                        })
                                    } else {
                                        let commandLevel3 = this.commandBuilder(ngZone, googleAnalyticsService, router, level3?.id, level1.name, level2.name, level3?.key_route, level3?.name)
                                        itemsSubMenu1.push(this.levelStructure(level3?.name, level3?.icon, level3?.key_route, null, commandLevel3))
                                        this.menuService?.addRoute(level3?.key_route);
                                    }
                                })
                            }
                        } else {
                            commandLevel2 = this.commandBuilder(ngZone, googleAnalyticsService, router, level2.id, level1.name, level2.name, level2.key_route, level2.name)
                        }
                        this.menuService?.addRoute(level2?.key_route);
                        itemsLevel.push(this.levelStructure(level2.name, level2.icon, level2.key_route, itemsSubMenu1, commandLevel2))
                    })
                } else {
                    commandLevel1 = this.commandBuilder(ngZone, googleAnalyticsService, router, level1.id, level1.name, level1.name, level1.key_route, level1.name)
                    this.menuService?.addRoute(level1?.key_route);
                }
            }

            if (level1.name !== 'Seguridad') {
                lateralMenu.push(this.levelStructure(level1.name, level1.icon, level1.key_route, itemsLevel, commandLevel1))
            } else {
                headerOptions.push(this.headerStructure(itemsLevel, commandLevel2))
            }
        })
        return [lateralMenu, headerOptions];
    }

    levelStructure(label: String, icon: String, path: String, items: Array<Object>, command: any = null): Object {
        let levelJson = {
            label: label,
            icon: icon,
            path: "",
            items: items
        };
        command ? levelJson["command"] = command : "";
        return levelJson;
    }

    headerStructure(items: Array<Object>, command: any = null): Object {
        let levelJson = {}
        items?.forEach((res: any) => {
            levelJson = {
                label: res?.label,
                icon: res?.icon,
                path: "",
            };
            command ? levelJson["command"] = command : "";
        })
        return levelJson;
    }

    commandBuilder(ngZone: NgZone, googleAnalyticsService: any, router: any, id: any, level1_name: any, menu_type: any, navPath: any, itemName: String) {
        return () => {
            ngZone.run(() => {
                googleAnalyticsService.eventEmitter(`Menu, ${id}, ${this.getSectionName(level1_name)}, ${this.getTypeName(menu_type)}, ${this.getBtnName(itemName)}`)
                router.navigate([navPath])
            })
        }
    }

    getBtnName(name: String): String {
        let btnName = "btn"

        if (name === "Reporte personalizado") {
            return "btn_estado_cuenta";
        }

        if (name === "Tarjetas de crédito") {
            return "btn_tdc";
        }

        if (name === "Transacciones masivas") {
            return "btn_estado_transacciones";
        }
        if (name == "PagoAcepta y Visanet") {
            return "btn_pagoacepta_visanet";
        }

        name.split(" ").forEach((c: String) => {
            switch (c.toLocaleLowerCase()) {
                case "de":
                case "a":
                case "y":
                case "desde":
                case "entre":
                    break;
                default:
                    let normalizeWord = c.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                    btnName += `_${normalizeWord}`;
            }
        })

        return btnName;
    }

    getTypeName(input: String) {
        if (input === "Tarjetas de crédito") {
            return "tarjeta_credito";
        }
        if (input === "Tarjetas de débito") {
            return "tarjeta_debito";
        }
        if (input === "Cheques") {
            return "cheques";
        }

        if (input === "Transacciones masivas") {
            return "transacciones_masivas";
        }
        return "menu_lateral";
    }

    getSectionName(levelName: String) {
        let typeName = "seccion";

        levelName.split(" ").forEach((c: String) => {
            let normalizeWord = c.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            typeName += `_${normalizeWord}`
        })

        return typeName;

    }
}

