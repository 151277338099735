<router-outlet></router-outlet>

<root-modal-content id="inactivity-modal"
                    [columnStyles]="'col-11'"
                    [closeModalIcon]="false"
                    (modalClosed)="closeModal('inactivity-modal')"
                    (modalSubmit)="closeModal('inactivity-modal', true)">
    <div class="text-center mt-4">
        <i class="icon-idea"></i>
        <h5 class="text-center mt-4 mb-3">{{ 'logout.inactivity.title' | translate }}</h5>
        <span class="small px-xl-3">{{ 'logout.inactivity.desc' | translate }}</span>
    </div>
    <div class="d-flex row d-flex justify-content-center mx-0 mt-4 mb-3">
        <div class="col-6 col-md-4 px-0 py-2 btn-container text-center"
             (click)="closeModal('inactivity-modal', true)">
            <h6>{{ 'general.understood' | translate | uppercase }}</h6>
        </div>
    </div>
</root-modal-content>
<sve-language-modal *ngIf="logOutService.showChangeLanguage"
                          [showLanguage]="logOutService.showChangeLanguage"
                          (showLanguageEmit)="clickCloseLanguage();">
</sve-language-modal>
<front-lib-loader 
[show]="loader?.getIsLoader() || false"
message="{{ (loader?.getMessage() || 'general.loading') | translate }}">
</front-lib-loader>