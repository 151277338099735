import {Component, OnInit, NgZone, HostListener, ChangeDetectorRef} from '@angular/core';
import { NavigationEnd, NavigationStart, Router} from '@angular/router';
import {EventBusRootService, GoogleAnalyticsService} from "@com-bam/lib-micro-communication";
import { TranslateService } from '@ngx-translate/core';
import { MenuService } from 'src/app/services/menu/menu.service';
import {LogoutService} from '../../services/logout/logout.service';
import {ModalContentService} from '../modal-content/services/modal-content.service';
import { LoadSpinnerService } from '@com-bam/front-web-lib';
import { menuConfig } from './menu.config';
@Component({
    selector: 'app-root-header-menu',
    templateUrl: './header-menu.component.html',
    styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent extends menuConfig  implements OnInit {

    menuIsOpen = true;
    showHeaderMenu = false;
    userInfo: any
    items:Array<any> = [] 


    @HostListener('window:beforeunload', ['$event'])
    onbeforeunload(event: any): boolean {
        this.modalService.open('modal-refresh-session');
        event.preventDefault();
        return false;
    } 

    @HostListener('window:unload', ['$event'])
    async unloadHandler(event) {
        this.logoutEvent();
    }
    

    constructor(private readonly router: Router,
                private readonly ngZone: NgZone,
                private readonly eventBus: EventBusRootService,
                private readonly modalService: ModalContentService,
                private readonly googleAnalyticsService: GoogleAnalyticsService,
                public logoutService: LogoutService,
                public loader: LoadSpinnerService,
                private readonly ref: ChangeDetectorRef,
                public translate: TranslateService,
                public override menuService: MenuService)
                 {
                    super(menuService)
    }

    ngOnInit(): void {
        this.loader.setIsLoader(true);
        this.getUserInfo();
        this.eventBus?.on('change_language', (data: any) => {
            this.translate.use(data);
            setTimeout(() => {
                this.ref.detectChanges();
            }, 1000)
        });
        this.menuService.getmenuByRol().subscribe((res:any)=>{
           this.items = this.getMenu(this.router, '', this.ngZone, this.googleAnalyticsService, res);
           this.items[0] = this.orderMenu();
           this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd || event instanceof NavigationStart) {
              if (!this.menuService.isValidRoute(event.url)) {
                if(!(event.url === '/consolidated/product')){
                    this.modalService.open('ROLE_PERMISIONS_ERROR');
                }
              }
            }
          });
           this.loader.setIsLoader(false);
        },(error: any)=>{
            this.modalService.open('modal-error');
            this.loader.setIsLoader(false);
        });
    }

    orderMenu(): any {
        let setOrder = [];
        this.items[0]?.forEach(element => {
            switch(element?.label){
                case 'Resumen':
                    setOrder.splice(0 , 0, element);
                    break;
                case 'Transferir':
                    setOrder.splice(1, 0, element);
                    break;
                case 'Pagar':
                    setOrder.splice(2, 0, element);
                    break;
                case 'Nómina':
                    setOrder.splice(3, 0, element);
                    break;
                case 'Administrar productos':
                    setOrder.splice(4, 0, element);
                    break;
                case 'Consultar':
                    setOrder.splice(5, 0, element);
                    break;
                case 'Otras transacciones':
                    setOrder.splice(6, 0, element);
                    break;
                case 'Configurar':
                    setOrder.splice(7, 0, element);
                    break;
                case 'Configurar':
                    setOrder.splice(8, 0, element);
                    break;
                default: 
                    setOrder.splice(9, 0, element);
                    break;
            }
        });
        return setOrder;
    }

    disableBack(): void {
        window.location.hash = 'no-back-button';
        window.location.hash = 'Again-No-back-button';
        window.onhashchange = () => {
            window.location.hash = 'no-back-button';
        };
    }

    clickIcon(): void {
        this.googleAnalyticsService.eventEmitter('Menu', '12', 'header', 'header', 'btn_logo_bam');
        this.showHeaderMenu = false;
        ((window.location.pathname === '/home' || window.location.pathname === '/consolidated/product')) ? '' : this.goToHome();
    }

    clickIconHelp(): void {
        this.googleAnalyticsService.eventEmitter('Menu', '13', 'header', 'header', 'btn_ayuda');
        this.showHeaderMenu = false;
    }

    clickIconTest(): void {
        this.googleAnalyticsService.eventEmitter('Menu', '14', 'header', 'header', 'btn_empresa');
        this.showHeaderMenu = false;
    }

    clickIconArrow(): void {
        this.googleAnalyticsService.eventEmitter('Menu', '15', 'header', 'header', 'btn_avatar_nombre');
    }

    menuSelect(items: any, index: number): void {
        const tempIsActive = items[index].isActive;
        const tempOpenOptions = items[index].openOptions;
        items.forEach((item: any) => {
            item.isActive = false;
            item.openOptions = false;
        });
        if (items[index].command) {
            items[index].command();
        } else {
            items[index].openOptions = !tempOpenOptions;
        }
        items[index].isActive = !tempIsActive;
    }

    itemSelect(items: any, index: number): void {
        const tempState = items[index].isActive;
        items.forEach((item: any) => {
            item.isActive = false;
        });
        if (items[index].command) {
            items[index].command();
        }
        items[index].isActive = !tempState;
    }

    logoutEvent(): void {
        this.googleAnalyticsService.eventEmitter('Menu', '52', 'cerrar_sesion', 'menu_lateral', 'btn_cerrar_sesion');
        this.logoutService.revokeToken()?.subscribe();
        this.eventBus.setIsLogOut(true);
        this.eventBus.setUserInfo(undefined);
        this.router.navigate(['login']);
    }

    getUserInfo(): void {
        this.logoutService.getUserInfo()?.subscribe((res: any)  => {
            this.userInfo = res;
        })
    }

    open(): void {
        this.validateActiveRoute();
        this.menuIsOpen = !this.menuIsOpen;
        this.showHeaderMenu = false;
    }

    validateActiveRoute(): void {
        this.items[0]?.forEach((item: any) => {
            if (item.items.length > 0) {
                item.items?.forEach((subItem: any) => {
                    if (subItem.items.length > 0) {
                        subItem.items?.forEach((subItemlv2: any) => {
                            if (subItemlv2.path === this.router.url.replace('/', '')) {
                                item.isActive = true;
                            } else {
                                item.isActive = false;
                            }
                        });
                    } else {
                        if (subItem.path === this.router.url.replace('/', '')) {
                            item.isActive = true;
                        } else {
                            item.isActive = false;
                        }
                    }
                });
            } else {
                if (item.path === this.router.url.replace('/', '')) {
                    item.isActive = true;
                } else {
                    item.isActive = false;
                }
            }
        });
    }

    closeSession(): void {
        this.googleAnalyticsService.eventEmitter('Cerrar Sesion', '10', 'cerrar_sesion', 'modal_alerta', 'btn_cerrar_si')
        this.closeModal();
        this.logoutEvent();
    }

    closeModal(): void {
        this.modalService.close('modal-refresh-session');
        this.modalService.close('modal-error');
        this.modalService.close('modal-error-role');
    }

    redirect(): void {
        this.router.navigate(['/layouts/home']);
    }

    closeHeaderMenu(): void {
        this.showHeaderMenu = false;
    }

    changeLanguage(): void {
        this.logoutService.showChangeLanguage = true;
    }

    goToHome(): void {
        this.router.navigate(['home']);
    }
    

}
