import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RolesComponent} from './containers/roles/roles.component';
import {AuthorizationGuard} from './core/guard/authorization.guard';
import {ApprovalFlowComponent} from './containers/approval-flow/approval-flow.component';
import {CreateUsersComponent} from './containers/create-users/create-users.component';
import {ProductsGroupComponent} from './containers/products-group/products-group.component';
import {PendingApprovalsComponent} from './containers/pending-approvals/pending-approvals.component';
import {ConsolidatedProductsComponent} from './containers/consolidated-products/consolidated-products.component';
import {TransferOwnAccountsComponent} from "./containers/transfer-own-accounts/transfer-own-accounts.component";
import {ChequesComponent} from "./containers/cheques/cheques.component";
import { FavoritesComponent } from './containers/favorites/favorites.component';
import { AchTransferComponent } from './containers/ach-transfer/ach-transfer.component';
import {TransferThirdsBamComponent} from "./containers/transfer-thirds-bam/transfer-thirds-bam.component";
import { BridgeComponent } from './containers/bridge/bridge.component';
import { DeclaraguateComponent } from './containers/declaraguate/declaraguate.component';
import { TdcPaymentComponent } from './containers/tdc-payment/tdc-payment.component';
import { PayrollComponent } from './containers/payroll/payroll.component';
import {TransferInternationalComponent} from './containers/transfer-international/transfer-international.component';
import {TransferOtherBanksComponent} from "./containers/transfer-other-banks/transfer-other-banks.component";
import { CashAdvanceComponent } from './containers/cash-advance/cash-advance.component';
import {PaymentServicesComponent} from './containers/payment-services/payment-services.component';
import { WithdrawDepositAccountsComponent } from './containers/withdraw-deposit-accounts/withdraw-deposit-accounts.component';
import { TDDComponent } from './containers/tdd/tdd.component';
import { LimitsTdcComponent } from './containers/limits-tdc/limits-tdc.component';
import { EasyCashComponent } from './containers/easy-cash/easy-cash.component';
import { ConfigureCardsComponent } from './containers/configure-cards/configure-cards.component';
import { AccountStatementsComponent } from './containers/account-statements/account-statements.component';
import { ConsultScheduledComponent } from './containers/consult-scheduled/consult-scheduled.component';
import { ConsultExchangeRateComponent } from './containers/consult-exchange-rate/consult-exchange-rate.component';
import { VinculacionLoadComponent } from './containers/vinculacion-load/vinculacion-load.component';
import { TravelNoticeComponent } from './containers/travel-notice/travel-notice.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        loadChildren: () => import('./containers/login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'android',
        loadChildren: () => import('./containers/android/android.module').then(m => m.AndroidModule)
    },
    {
        path: 'bancasat/confirmation',
        component: BridgeComponent
    },
    {
        path: 'user-recovery',
        loadChildren: () => import('./containers/user-recovery/user-recovery.module').then(m => m.UserRecoveryModule)
    },
    {
        path: 'users-registration',
        loadChildren: () => import('./containers/users-registration/users-registration.module').then(m => m.UsersRegistrationModule)
    },
    {
        path: 'home',
        canActivate: [AuthorizationGuard],
        loadChildren: () => import('./containers/home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'pwd-recovery',
        loadChildren: () => import('./containers/pwd-recovery/pwd-recovery.module').then(m => m.PwdRecoveryModule)
    },
    {
        path: 'change-password',
        canActivate: [AuthorizationGuard],
        loadChildren: () => import('./containers/change-password/change-pwd.module').then(m => m.ChangePasswordModule)
    },
    {
        path: 'create-users',
        canActivate: [AuthorizationGuard],
        component: CreateUsersComponent,
        children: [
            {
                path: 'consult',
                component: CreateUsersComponent
            },
            {
                path: 'create',
                component: CreateUsersComponent
            },
            {
                path: 'edit',
                component: CreateUsersComponent
            }
        ]
    },
    {
        path: 'roles',
        canActivate: [AuthorizationGuard],
        component: RolesComponent,
        children: [
            {
                path: 'consult',
                component: RolesComponent
            },
            {
                path: 'create',
                component: RolesComponent
            },
            {
                path: 'edit',
                component: RolesComponent
            }
        ]
    },
    {
        path: 'products-group',
        component: ProductsGroupComponent,
        canActivate: [AuthorizationGuard],
        children: [
            {
                path: 'consult',
                component: ProductsGroupComponent
            },
            {
                path: 'create',
                component: ProductsGroupComponent
            },
            {
                path: 'edit',
                component: ProductsGroupComponent
            }
        ]
    },
    {
        path: 'approval-flow',
        component: ApprovalFlowComponent,
        canActivate: [AuthorizationGuard],
        children: [
            {
                path: 'consult',
                component: ApprovalFlowComponent
            },
            {
                path: 'create',
                component: ApprovalFlowComponent
            },
            {
                path: 'edit',
                component: ApprovalFlowComponent
            }
        ]
    },
    {
        path: 'pending-approvals',
        component: PendingApprovalsComponent,
        canActivate: [AuthorizationGuard],
        children: [
            {
                path: 'administrative-transactions',
                component: PendingApprovalsComponent
            },
            {
                path: 'monetary-transactions',
                component: PendingApprovalsComponent
            }
        ]
    },
    {
        path: 'consolidated',
        component: ConsolidatedProductsComponent,
        canActivate: [AuthorizationGuard],
        children: [
            {
                path: 'product',
                component: ConsolidatedProductsComponent
            },
            {
                path: 'movements',
                component: ConsolidatedProductsComponent,
                children: [
                    {
                        path: 'accounts',
                        component: ConsolidatedProductsComponent
                    },
                    {
                        path: 'credit-cards',
                        component: ConsolidatedProductsComponent
                    },
                    {
                        path: 'loans',
                        component: ConsolidatedProductsComponent
                    }
                ]
            }
        ]
    },
    {
        path: 'cheques',
        component: ChequesComponent,
        canActivate: [AuthorizationGuard],
        children: [
            {
                path: 'declare',
                component: ChequesComponent,
                children: [
                    {
                        path: 'unique',
                        component: ChequesComponent,
                    },
                    {
                        path: 'massive',
                        component: ChequesComponent,
                    },
                ]
            },
            {
                path: 'request',
                component: ChequesComponent,
            },
            {
                path: 'consult',
                component: ChequesComponent,
            },
            {
                path: 'lay-off',
                component: ChequesComponent,
            }
        ]
    },
    {
        path: 'transfer',
        canActivate: [AuthorizationGuard],
        component: TransferOwnAccountsComponent,
        children: [
            {
                path: 'own-accounts',
                component: TransferOwnAccountsComponent
            }
        ]
    },
    {
        path: 'transfer-bam',
        canActivate: [AuthorizationGuard],
        component: TransferThirdsBamComponent,
        children: [
            {
                path: 'thirds',
                component: TransferThirdsBamComponent,
                children: [
                    {
                        path: 'single-or-multiple',
                        component: TransferThirdsBamComponent
                    },
                    {
                        path: 'massive',
                        component: TransferThirdsBamComponent
                    }
                ]
            }
        ]
    },
    {
        path: 'transfer-other',
        canActivate: [AuthorizationGuard],
        component: TransferOtherBanksComponent,
        children: [
            {
                path: 'banks',
                component: TransferOwnAccountsComponent
            }
        ]
    },
    {
        path: 'loans-payment',
        canActivate: [AuthorizationGuard],
        loadChildren: () => import('./containers/loans-payment/loans-payment.module').then(m => m.LoansPaymentModule)
    },
    {
        path: 'ach-transfer',
        canActivate: [AuthorizationGuard],
        component: AchTransferComponent,
        children: [
            {
                path: 'multiple',
                component: AchTransferComponent
            },
            {
                path: 'massive',
                component: AchTransferComponent
            }
        ]
    },
    {
        path: 'favorites',
        component: FavoritesComponent,
        canActivate: [AuthorizationGuard],
        children: [
            {
                path: 'third-bam',
                component: FavoritesComponent
            },
            {
                path: 'others-banks',
                component: FavoritesComponent
            },
            {
                path: 'ach-debit',
                component: FavoritesComponent
            },
            {
                path: 'international',
                component: FavoritesComponent
            },
            {
                path: 'services-favorites',
                component: FavoritesComponent
            }
        ]
    },
    {
        path: 'bancaSAT',
        canActivate: [AuthorizationGuard],
        loadChildren: () => import('./containers/banca-sat/banca-sat.module').then(m => m.BancaSatModule)
    },
    {
        path: 'declaraguate',
        canActivate: [AuthorizationGuard],
        component: DeclaraguateComponent,
    },
    {
        path: 'tdc-payment',
        canActivate: [AuthorizationGuard],
        component: TdcPaymentComponent,
        children: [
            {
                path: 'third-bam',
                component: TdcPaymentComponent
            },
            {
                path: 'owns',
                component: TdcPaymentComponent
            }
        ]
    },
    {
        path: 'payroll',
        canActivate: [AuthorizationGuard],
        component: PayrollComponent,
        children: [
            {
                path: 'single-or-multiple',
                component: PayrollComponent
            },
            {
                path: 'massive',
                component: PayrollComponent
            }
        ]
    },

    {
        path: 'transfer-international',
        canActivate: [AuthorizationGuard],
        component: TransferInternationalComponent,
        children: [
            {
                path: 'single',
                component: TransferInternationalComponent
            },
            {
                path: 'massive',
                component: TransferInternationalComponent
            }
        ]
    },
    {
        path: 'cash-advance',
        canActivate: [AuthorizationGuard],
        component: CashAdvanceComponent,

    },
    {
        path:'tdc',
        canActivate: [AuthorizationGuard],
        component: LimitsTdcComponent,
        children: [
            {
                path: 'manage-card',
                component: LimitsTdcComponent
            },
            {
                path: 'transfer-limits',
                component: LimitsTdcComponent
            }
        ]

    },
    {
        path: 'services',
        canActivate: [AuthorizationGuard],
        component: PaymentServicesComponent,
    },
    {
        path: 'withdraw-deposit-accounts',
        canActivate: [AuthorizationGuard],
        component: WithdrawDepositAccountsComponent,
    },
    {
        path: 'tdd',
        canActivate: [AuthorizationGuard],
        component: TDDComponent,        
        children: [
            {
                path: 'transfer-funds',
                component: TDDComponent
            }
        ]
    },
    {
        path: 'easy-cash',
        canActivate: [AuthorizationGuard],
        component: EasyCashComponent,
        children: [
            {
                path: 'massive',
                component: EasyCashComponent
            },
            {
                path: 'to-perform',
                component: EasyCashComponent
            },
            {
                path: 'employed',
                component: EasyCashComponent
            }
        ]
    },
    {
        path: 'configure-cards',
        canActivate: [AuthorizationGuard],
        component: ConfigureCardsComponent,
        children: [
            {
                path: 'tdc-manage',
                component: ConfigureCardsComponent
            },
            {
                path: 'tdd-manage',
                component: ConfigureCardsComponent
            }
        ]
    },
    {
        path: 'custom-report',
        canActivate: [AuthorizationGuard],
        loadChildren: () => import('./containers/custom-report/custom-report.module').then(m => m.CustomReportModule)
    },
    {
        path: 'consult-transaction',
        canActivate: [AuthorizationGuard],
        loadChildren: () => import('./containers/consult-transaction/consult-transaction.module').then(m => m.ConsultTransactionModule)
    },
    {
        path: 'account-statements',
        canActivate: [AuthorizationGuard],
        component: AccountStatementsComponent,
    },
    {
        path: 'consult-massive',
        canActivate: [AuthorizationGuard],
        loadChildren: () => import('./containers/consult-massive/consult-massive.module').then(m => m.ConsultMassiveModule)
    },
    {
        path: 'consult-scheduled',
        canActivate: [AuthorizationGuard],
        component: ConsultScheduledComponent,
        children: [
            {
                path: 'scheduled',
                component: ConfigureCardsComponent
            },
            {
                path: 'executed',
                component: ConfigureCardsComponent
            }
        ]
       
    },
    {
        path: 'consult-exchange-rate',
        canActivate: [AuthorizationGuard],
        loadChildren: () => import('./containers/consult-exchange-rate/consult-exchange-rate.module').then(m => m.ConsultExchangeRateModule)
    },
    {
        path: 'digital-bonding',
        canActivate: [AuthorizationGuard],
        component:VinculacionLoadComponent,
        children: [
            {
                path: 'load',
                component: VinculacionLoadComponent
            }
        ]
    },
    {
        path: 'travel-notification',
        canActivate: [AuthorizationGuard],
        component: TravelNoticeComponent,
        children: [
            {
                path: 'debit',
                component: TravelNoticeComponent
            },
            {
                path: 'credit',
                component: TravelNoticeComponent
            }
        ]
    }
        
    

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
