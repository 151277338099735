import { Component } from '@angular/core';
import {EventBusRootService} from "@com-bam/lib-micro-communication";

@Component({
  selector: 'app-root-login',
  templateUrl: './login.component.config.html'
})
export class LoginComponentConfig {
  constructor(private eventBus: EventBusRootService) {
    if(!sessionStorage.getItem('language')){
      const ln = window.navigator.language || navigator['browserLanguage'];
      if(ln === 'en-US' || ln === 'en') {
        sessionStorage.setItem('language', 'en')
      } else {
        sessionStorage.setItem('language', 'es')
      }
    }
  }
  changeLanguage(): void {
    this.eventBus.emit({name: 'change_language', data: sessionStorage.getItem('language')});
  }
}
