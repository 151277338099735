<footer class="p-3 p-xl-4">
    <div *ngIf="!eventBus.getUserInfo()"
         class="pb-3 d-flex flex-column flex-md-row justify-content-end align-items-center">
        <ng-container *ngFor="let link of footerLinks; let i = index">
            <a id="footer-link-{{i}}"
               class="small mt-4 mt-md-0"
               [href]="link.route"
               target="_blank"
               style="text-decoration: unset;">
                {{link?.label}}
            </a>
            <div *ngIf="i < footerLinks?.length - 1"
                 class="link-point-separator mx-3 d-none d-md-block"></div>
        </ng-container>
    </div>
    <div class="pt-3 d-md-flex flex-md-row justify-content-between separator">
        <div class="column-1">
            <div class="d-none d-md-block">
                <img [src]="logoFooter">
            </div>
            <div class="logo-banco text-center d-md-none">
                <img [src]="logoFooter">
            </div>
            <div class="small my-3 mb-md-0 d-none d-md-block">{{'general.groupCopyright' | translate}} {{year}} {{'general.groupName' | translate}}</div>
            <div class="small my-3 mb-md-0 text-center d-md-none">{{'general.groupCopyright' | translate}} {{year}} {{'general.groupName' | translate}}</div>
        </div>
        <div class="column-2">
            <div *ngIf="!eventBus.getUserInfo()" style="text-align: center;">
                <img class="logo-secured" [src]="logoDigicert"/>
            </div>
        </div>
    </div>
</footer>
