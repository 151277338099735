import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-bridge',
  template: '<div></div>'
})
export class BridgeComponent implements OnInit {

  queryParam = '';

  constructor(private readonly route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      this.queryParam = params.toString();
      const data = params;
      const event = new CustomEvent('myCustomEvent', {detail: data});
      window.parent.document.dispatchEvent(event);
    });
  }
}
