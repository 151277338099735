import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService, HttpProxy } from '@com-bam/lib-micro-communication';
import { Observable } from 'rxjs';
import { environment, clientID } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {
  showChangeLanguage = false;
  private readonly proxy!: HttpProxy;
  
  constructor(private readonly http:  HttpClient,
    private readonly configService: ConfigService) {
      this.proxy = new HttpProxy(this.http, environment.API_SERVICES.API_COMMONS, this.configService);
    }

  public revokeToken<T>(): Observable<T> {
    const payload = {
      client_id: clientID,
      token: this.configService.config.token.value
    };
    return this.proxy.post<T>('security/v1/auth/revoke', payload);
  }

  public refreshToken(refresh_token: any): Observable<any> {
    const payload = {grant_type: 'refresh_token', client_id: clientID, refresh_token};
    return this.proxy.post('security/v1/auth/token', payload);
  }

  public getUserInfo(): Observable<any> {
    return this.proxy.get(`security/v1/auth/userinfo`, '');
  }
}
