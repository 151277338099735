import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigService, HttpProxy } from "@com-bam/lib-micro-communication";
import { Observable, of } from "rxjs";
import { environment, clientID } from "../../../environments/environment";
import { asyncError, asyncData } from "../../../testing/async-observable-helpers.config"



@Injectable({
  providedIn: "root",
})
export class MenuService {
  routesList: any[] = [];
  private readonly proxy!: HttpProxy;

  constructor(
    private readonly http: HttpClient,
    private readonly configService: ConfigService
  ) {
    this.proxy = new HttpProxy(
      this.http,
      environment.API_SERVICES.API_ENTERPRISES,
      this.configService
    );
  }

  public getmenuByRol(): Observable<any> {
    return this.proxy.get(`users/v1/sve/roles/menu`, ''); 
  }

  addRoute(route: string): void {
    if (route?.startsWith('/')) {
      this.routesList.push(route);
    } else {
      this.routesList.push('/' + route);
    }
  }

  public isValidRoute(route: string): boolean {
    if (this.routesList.indexOf(route) > -1) {
      return true;
    } else {
      return false;
    }
  }

  public resetRoutesList(): void {
    this.routesList = [];
  }

}
