import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {EventBusRootService} from '@com-bam/lib-micro-communication';

@Injectable()
export class AuthorizationGuard implements CanActivate {

  constructor(private readonly mEventBus: EventBusRootService) {
  }

  canActivate(): boolean {
    const user = this.mEventBus.getUserInfo();
    if (!user) {
      const micro = {
        goTo: 'login'
      };
      this.mEventBus.emit({name: 'mountedApp', data: micro});
      return false;
    }
    return true;
  }
}
